<template>
	<div class="servicedetail">
		<!-- header -->
		<homePage :urlFlag="2"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_service_5.png">
        </div>
        <div class="container newsList">
            <div class="row">
                <div class="col-md-9 article">
                    <h1>贸易代理</h1>
                    <div class="articleDetail">
                        <p>根据您的需求我们会您提供更多的优质货源或者国内销售渠道，供应链金融服务，税费办理。</p>
                        <img src="@/assets/img_service_text_5.png" alt="">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contactUs">
                        <div class="contactTitle">联系我们</div>
                        <div class="contactName">Fisher YU</div>
                        <div class="person-contact">
                            <div>
                                <div class="icon font_family icon-icon_phone_white"></div><div>servicepinkoi</div>
                            </div>
                            <div>
                                <div class="icon font_family icon-icon-mail"></div><div>srl-market@srleader.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <!-- 成功案例 -->
            <div class="cases">成功案例</div>
            <div class="allCases">
                <div class="case-box-hover"><img src="@/assets/img_service_5_1.png" alt=""><p>财税顾问</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_5_2.png" alt=""><p>投资顾问</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_5_3.png" alt=""><p>税费代理</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_5_4.png" alt=""><p>商务资讯</p></div>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "servicedeputy",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
		}
	},
	methods: {
	},
	mounted() {
	},
};
</script>

<style scoped>
@import "servicedeputy.css";
</style>
